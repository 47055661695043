export const table = [
    {
        column: 'Código',
    },
    {
        column: 'Mentoria',
    },
    {
        column: 'Válido a partir',
    },
    {
        column: 'Válido até',
    },
    {
        column: 'Ações',
    }
];
// 'evaluations'
export const keys = ['code', 'mentoring_name', 'valid_from', 'valid_until', 'actions'];
