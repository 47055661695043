<template>
    <Layout>
        <b-card>
            <FilterBase
                session="Coupon"
                :filter="filter"
                :button="true"
                url="/cupons/cadastrar"
            />

            <div class="mb-0 mt-3">
                <TableList
                    session="Coupon"
                    url="cupons/"
                    :table="table"
                    :keys="keys"
                    :key="tableKey"
                >

                    <template #valid_from="{value}">
                        {{convertDateText(value.valid_from)}}
                    </template>

                    <template #valid_until="{value}">
                        <span v-if="value.valid_until">{{convertDateText(value.valid_until)}}</span>
                        <span v-else>N/A</span>
                    </template>

                    <template #actions="{value}">
                        <Actions
                            :id="value.id"
                            :types="types"
                            api="coupons/"
                            url="cupons/"
                            session="Coupon"
                        />
                    </template>

                </TableList>
            </div>
        </b-card>
    </Layout>

</template>

<script>
import Layout from '@/components/layout/main.vue';
import TableList from "@/components/base/table-list.vue";
import FilterBase from '@/components/base/filter-component.vue';
import Actions from "@/components/base/actions.vue";
import {filter} from "@/views/coupon/js/filter";
import {keys, table} from "@/views/coupon/js/table";
import {setSessions} from "@/components/composables/setSessions";
import {convertDateText} from "../../components/composables/masks";

export default {
    components: {
        Layout,
        FilterBase,
        TableList,
        Actions
    },
    methods: {
        convertDateText,
        refreshTable(){
            this.tableKey++;
        }
    },
    data() {
        return {
            types: ['edit', 'delete'],
            tableKey: 0,
            filter,
            table,
            keys
        }
    },
    mounted() {
        if (!localStorage.getItem('Coupon')) setSessions('Coupon');
    }
}
</script>
